import React from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import withRouter from '../../../helpers/withRouter';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToggleSidebar } from "../../../redux/actions";
import { useTheme } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { getTopItems, bottonItems } from "../../../data/navbar-items";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import SidebarStyles from "../../../styles/jss/components/SidebarStyles";
import clsx from "clsx";
import { ToggleSidebarItem } from "../../../redux/actions";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from "axios";
import { cfg } from "../../../config";

const useStyles = makeStyles(SidebarStyles);

const SidebarItems = (props) => {
  const [allowedApplications, setAllowedApplications] = React.useState([]);

  const theme = useTheme();
  const classes = useStyles();
  const open = useSelector((state) => state.sidebarItems);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const setOrgData = (org) => {
    // Set allowed aircrafts
    setAllowedApplications(org.data.included_apps);
  }

  React.useEffect(() => {
    // Get org
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          console.log(resp.data)
          setOrgData(resp.data)
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, []);

  const switchLocation = (to) => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      navigate(to);
      dispatch(ToggleSidebar());
    } else navigate(to);
  };

  const handleClick = (item) => {
    if (item.to) {
      if (window.innerWidth < theme.breakpoints.values.sm) {
        navigate(item.to);
        dispatch(ToggleSidebar());
      } else navigate(item.to);
    } else {
      if (item.items && item.items.length > 0) {
        dispatch(ToggleSidebarItem(item.title));
      }
    }
  };

  const getList = (item) => {
    const { title, icon, to } = item;
    return (
      <div key={title}>
        <ListItem
          button
          key={title}
          onClick={() => handleClick(item)}
          className={clsx(classes.menuItems, {
            [classes.selected]: location.pathname === to,
          })}
        >
          {icon && (
            <ListItemIcon style={{ color: "inherit" }}>
              {icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={title}
            classes={{
              primary: location.pathname === to && classes.selected,
            }}
          />
          {item.items &&
            item.items.length > 0 &&
            (open.openItems.indexOf(title) > -1 ? (
              <ExpandMoreIcon />
            ) : (
              <ExpandLessIcon />
            ))}
        </ListItem>
        {item.items && item.items.length > 0 && (
          <Collapse
            in={open.openItems.includes(item.title)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.items.map((drop) => (
                <ListItem
                  button
                  key={drop.title}
                  className={clsx(classes.menuItems, classes.nested, {
                    [classes.selected]: location.pathname === drop.to,
                  })}
                  onClick={() => switchLocation(drop.to)}
                >
                  <ListItemText
                    primary={drop.title}
                    classes={{
                      primary:
                        location.pathname === drop.to &&
                        classes.selected,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </div>
    );
  }

  return (
    <div className={classes.topContainer}>
      <div className={classes.header}>
        <img
          src={"/AeronauticaLight_NoTag.svg"}
          alt="Aeronautica"
          className={classes.logo}
        />
        <IconButton
          onClick={() => dispatch(ToggleSidebar())}
          className={classes.icons}
          size="large">
          <MenuOpenIcon />
        </IconButton>
      </div>
      <div className={classes.container}>
        <List>
          {getTopItems(allowedApplications).map((item) => {
            return getList(item);
          })}
        </List>
        <List>
          {bottonItems.map((item) => {
            return getList(item);
          })}
        </List>
      </div>
    </div>
  );
};

export default withRouter(SidebarItems);
