import React from "react";
import ApplicationCard from "../components/common/ApplicationCard";
import axios from "axios";
import { cfg } from "../config";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Grid } from "@mui/material";


const Applications = () => {
  const [allowedAircrafts, setAllowedAircrafts] = React.useState([]);
  const [allowedApplications, setAllowedApplications] = React.useState([]);

  const setOrgData = (org) => {
    // Set allowed applications
    setAllowedApplications(org.data.included_apps);
    // // Set allowed aircrafts
    // setAllowedAircrafts(org.data.included_apps);

    // Set allowed aircrafts
    fetchAuthSession().then((session) => {
      axios
        .get(
          `${cfg.apiUrl}/app/aspec/config`,
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          const configData = resp.data;
          console.log(configData);

          // Get lists of aircrafts
          const all_aircraft = configData.aircrafts;
          const basic_aircraft = all_aircraft.filter(aircraft => aircraft.category === "basic");

          // Filter by plan (basic, premium)
          const isSubscriptionPremium = org.billing.plan === "premium";
          let includedAircrafts = isSubscriptionPremium ? all_aircraft : basic_aircraft;

          // Filter by included tags
          const includedTags = org?.data?.included_tags;
          if (includedTags) {
            includedAircrafts = includedAircrafts.filter(aircraft => !("tag" in aircraft) || includedTags.includes(aircraft.tag));
          }

          // Set allowed aircrafts
          setAllowedAircrafts(includedAircrafts.map(aircraft => ({
            value: aircraft.name,
            label: aircraft.name,
            category: aircraft.category
          })));
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  // Update input variants when missionMix is changed.
  React.useEffect(() => {
    // Get org
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          setOrgData(resp.data)
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, []);

  const allApplications = [
    {
      title: "Fatigue Spectrum Analysis",
      subtitle: "ASPEC LOADSGEN",
      icon: "airplanemode_active",
      link: "/apps/aspec-loadsgen",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Fatigue Spectral Analysis project, please input a project name and select an aircraft.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          },
          {
            type: "select",
            label: "Aircraft Type",
            id: "aircraft",
            options: allowedAircrafts,
          },
        ],
        postUrl: "app/aspec/project",
        appApi: "aspec",
        app: "aspec-loadsgen",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Fuselage Unit Beam Solver",
      subtitle: "FUSELOADS",
      icon: "airplanemode_active",
      link: "/apps/fuseloads",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Fuselage Unit Beam Solver project, please input a project name and select an aircraft.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          },
          {
            type: "text",
            label: "Aircraft",
            id: "aircraft",
          },
        ],
        postUrl: "app/fuse/project",
        appApi: "fuse",
        app: "fuseloads",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Multi-bay Box Beam Analysis",
      subtitle: "WINGBOX",
      icon: "airplanemode_active",
      link: "/apps/wingbox",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Box Beam Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/boxbeam/project",
        appApi: "boxbeam",
        app: "wingbox",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Ring Frame Analysis",
      subtitle: "RINGFRAME",
      icon: "airplanemode_active",
      link: "/apps/ringframe",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Ring Frame Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/ring/project",
        appApi: "ring",
        app: "ringframe",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Wingspan Analysis",
      subtitle: "WINGSPAN",
      icon: "airplanemode_active",
      link: "/apps/wingspan",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Wingspan Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/wingspan/project",
        appApi: "wingspan",
        app: "wingspan",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    }
  ];

  return (
    <div>
      <Grid container spacing={3}>
        {allApplications.filter(app => allowedApplications.includes(app.subtitle)).map((app, idx) => (
          <ApplicationCard
            key={idx}
            showDialog={app.showDialog}
            title={app.title}
            subtitle={app.subtitle}
            icon={app.icon}
            dialogOptions={app.dialogOptions}
            link={app.link}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Applications;
