/*
TODO:
    1. Fix filtering function using helpers.filter
 */

import React from "react";
import DataTable from "react-data-table-component";
import {
  Checkbox,
  Box,
  Button,
  Typography,
  Card,
  LinearProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import ProjectListOptions from "./ProjectListOptions";
import ProjectListTableHeader from "./ProjectListTableHeader";
import ProjectListStyles from "../../../../styles/jss/components/apps/ProjectListStyles";
import { getAvailableOptions } from "../../../../helpers";
import NewProjectDialog from "../../../common/NewProjectDialog";
import withRouter from "../../../../helpers/withRouter";
import axios from "axios";
import { cfg } from "../../../../config";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";

const ProjectList = (props) => {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [parsedData, setParsedData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [allowedAircrafts, setAllowedAircrafts] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    // Get project
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/app/aspec/project`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          setIsLoading(false);
          setData(resp.data);
          setParsedData(resp.data);
          setOpen(resp.data.length < 1);
        })
        .catch((e) => {
          console.log(e);
        });
    });

    // Get org
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          const orgData = resp.data;

          // Set allowed aircrafts
          fetchAuthSession().then((session) => {
            axios
              .get(
                `${cfg.apiUrl}/app/aspec/config`,
                {
                  headers: {
                    Authorization: session.tokens?.idToken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((resp) => {
                const configData = resp.data;
                console.log(configData);

                // Get lists of aircrafts
                const all_aircraft = configData.aircrafts;
                const basic_aircraft = all_aircraft.filter(aircraft => aircraft.category === "basic");

                // Filter by plan (basic, premium)
                const isSubscriptionPremium = orgData.billing.plan === "premium";
                let includedAircrafts = isSubscriptionPremium ? all_aircraft : basic_aircraft;

                // Filter by included tags
                const includedTags = orgData?.data?.included_tags;
                if (includedTags) {
                  includedAircrafts = includedAircrafts.filter(aircraft => !("tag" in aircraft) || includedTags.includes(aircraft.tag));
                }

                // Set allowed aircrafts
                setAllowedAircrafts(includedAircrafts.map(aircraft => ({
                  value: aircraft.name,
                  label: aircraft.name,
                  category: aircraft.category
                })));
              })
              .catch((e) => {
                console.log(e);
              });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const sortByAircraft = async (input) => {
    let tempData = [];
    if (input === "") {
      setParsedData(data); // TODO: use data directly?
      return;
    }
    data.filter((i) => {
      i.aircraft === input && tempData.push(i);
    });
    setParsedData(tempData);
  };

  const sortBySearch = (input) => {
    console.log(data)
    if (input === "") {
      setParsedData(data);
    } else {
      let sortedData = [];
      for (let i = 0; i < data.length; i++) {
        for (const [key] of Object.entries(data[i])) {
          if (
            data[i][key] &&
            data[i][key]
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) !== -1
          ) {
            if (!sortedData.includes(data[i]))
              sortedData.push(data[i]);
          }
        }
      }
      setParsedData(sortedData);
    }
  };

  const refreshData = () => {
    fetchData();
  };

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row["name"],
      cell: (row) => (
        <Button
          classes={{ label: classes.buttonLabel }}
          disableRipple
          disableElevation
          disableFocusRipple
          color={"primary"}
          variant={"text"}
          onClick={() => navigate("/apps/aspec-loadsgen/project/" + row.id)}
        >
          {row.name}
        </Button>
      ),
      sortable: true,
    },
    {
      name: "Aircraft",
      selector: (row) => row["aircraft"],
      sortable: true,
    },
    {
      name: "# of Analysis",
      selector: (row) => row["analysisCount"],
      sortable: true,
    },
    {
      name: "Last Activity",
      selector: (row) => row["modified"],
      cell: (row) => <span>{moment(row.modified).format("ll LT")}</span>,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ProjectListOptions row={row} updateData={refreshData} />
      ),
      // allowOverflow: true,
      right: "true",
    },
  ];
  const dialogOptions = {
    title: "New Project",
    description:
      "To create a new Fatigue Spectral Analysis project, please input a project name and select an aircraft.",
    inputs: [
      {
        type: "text",
        label: "Project Name",
        id: "name",
        required: true,
      },
      {
        type: "select",
        label: "Aircraft Type",
        id: "aircraft",
        required: true,
        options: allowedAircrafts,
      },
    ],
    postUrl: "app/aspec/project",
    appApi: "aspec",
    app: "aspec-loadsgen"
  };

  const { classes } = props;

  return (
    <div>
      {isLoading ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <div>
          <NewProjectDialog
            updateData={() => fetchData()}
            handleClose={() => setOpen(false)}
            open={open}
            setOpen={setOpen}
            navigate={navigate}
            {...dialogOptions}
          />
          <Box className={classes.headerContainer}>
            <Typography variant={"h5"}>Project List</Typography>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => setOpen(true)}
            >
              + New Project
            </Button>
          </Box>
          <Card className={classes.tableContainer}>
            <DataTable
              style={{ marginTop: "0px" }}
              columns={columns}
              data={parsedData}
              striped
              pagination
              paginationPerPage={15}
              noHeader
              subHeader
              subHeaderComponent={
                <ProjectListTableHeader
                  sortBySearch={sortBySearch}
                  sortAircraft={sortByAircraft}
                  aircraft={getAvailableOptions(data, "aircraft")}
                />
              }
              selectableRowsComponent={Checkbox}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default withStyles(ProjectListStyles)(withRouter(ProjectList));
